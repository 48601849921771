import { Link } from 'gatsby'
import React from 'react'
import { Container } from 'react-bootstrap'
import * as styles from './footer.module.scss'

export default function Footer() {
  return (
    <div className={styles.footer}>
      <Container>
        <div className={styles.footerLinks}>
          <ul>
            {/* <li><Link to="/terms-conditions">Terms &amp; Conditions</Link></li> */}
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/user-login">User Login</Link></li>
          </ul>
        </div>
        <div className={`${styles.small} mb-4`}>
          © {new Date().getFullYear()} Fjord Advisors Limited. All rights reserved.
        </div>
        <div className={styles.small}>
          Authorised and regulated by the UK Financial Conduct Authority with number 538629. Registered in England and Wales with registration number 07472781. Registered office: Hainton Lodge, 138 High Street, Sutton Courtenay, Oxfordshire, OX14 4AX, United Kingdom.
        </div>
      </Container>
    </div>
  )
}
