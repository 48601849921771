import { Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as styles from "./header.module.scss";
import Logo from "../images/logo.png";

export default function Header() {
  return (
    <header className={styles.header}>
      <Container className={styles.headerContainer}>
        <Row>
          <Col>
            <ul className={styles.menu}>
              <li>
                <Link to="/#Contact">Contact</Link>
              </li>
            </ul>
          </Col>
          <Col xs="auto">
            <Link to="/">
              <StaticImage
                placeholder="none"
                alt="Fjord Header Logo"
                className={styles.logo}
                src="../images/logo.png"
              />
            </Link>
          </Col>
        </Row>
      </Container>
    </header>
  );
}
