import React from "react";
import Header from "./header";

import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";
import Footer from "./footer";
import Helmet from "react-helmet";

export default function Layout({ children }) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Fjord Capital Partners Ltd</title>
        <meta
          name="description"
          content="Strategic Private Equity Investment focussed on
resource efficiency solutions for industry"
        />
      </Helmet>

      <Header />
      {children}
      <Footer />
    </>
  );
}
